<template>
	<div>
		<h1>门诊统计</h1>
		<div class="flex align-items-c">
			<el-button @click="handleAdd" size="small" type="primary">新增服务记录</el-button>
			<el-button @click="getServiceRecordStatistics" size="small" style="margin-right: 10px;">刷新</el-button>
			<div class="flex align-items-c">
				<span class="margin-r-10">时间范围:</span>
				<el-date-picker v-model="dateRange" :picker-options="pickerOptions" @change="handleDateChange" type="daterange"
					align="left" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					value-format="yyyy-MM-dd" size="small" class="margin-r-10" style="width: 260px;">
				</el-date-picker>
			</div>
		</div>

		<el-row v-loading="loading" class="margin-t-20">
			<el-col v-for="item in services" :key="item.type" :span="4">
				<div @click="handleAdd(item.type)" class="num-statistics">
					<div class="num-statistics_title">{{ item.name }}</div>
					<div class="num-statistics_num">{{ item.count }}</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div class="num-statistics">
					<div class="num-statistics_title">今日就诊保障记录数量</div>
					<div class="num-statistics_num">{{ visit }}</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div class="num-statistics">
					<div class="num-statistics_title">今日班车保障次数</div>
					<div class="num-statistics_num">{{ bus }}</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div class="num-statistics">
					<div class="num-statistics_title">今日住院保障人数</div>
					<div class="num-statistics_num">{{ bih }}</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div class="num-statistics">
					<div class="num-statistics_title">疫苗接种情况</div>
					<div class="num-statistics_num">{{ vaccine }}</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div class="num-statistics">
					<div class="num-statistics_title">健康巡诊</div>
					<div class="num-statistics_num">{{ patrol }}</div>
				</div>
			</el-col>
		</el-row>

		<h3>今日就诊保障记录</h3>
		<el-button @click="getGuaranteeData" size="small" style="margin: 10px 0;">刷新</el-button>

		<el-table :data="guaranteeTableData" v-loading="guaranteeTableLoading" border
			:header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="60vh" style="width: 100%;overflow-y: auto;">
			<el-table-column label="姓名" prop="member_info.realname"></el-table-column>
			<el-table-column label="就诊类型" v-slot="{ row }">{{ row.type == 1 ? '日常陪诊' : '急诊' }}</el-table-column>
			<el-table-column label="陪诊人员" prop="member_escort.name"></el-table-column>
			<el-table-column label="医院" prop="hospital"></el-table-column>
			<el-table-column label="科室" prop="department"></el-table-column>
			<el-table-column label="医生" prop="doctor"></el-table-column>
		</el-table>
		<div class="margin-t-10 flex">
			<el-pagination background layout="prev, pager, next, total" :total="guaranteeTotal"
				:current-page.sync="guaranteeQueryForm.page" :page-size="guaranteeQueryForm.page_size" @current-change="getGuaranteeData">
			</el-pagination>
		</div>

		<h3>今日疫苗接种记录</h3>
		<el-button @click="getVaccinumData" size="small" style="margin: 10px 0;">刷新</el-button>

		<el-table :data="vaccinumTableData" v-loading="vaccinumTableLoading" border stripe
			:header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
			<el-table-column label="姓名" prop="member.realname"></el-table-column>
			<el-table-column label="疫苗名称" prop="type_name.type_name"></el-table-column>
			<el-table-column label="接种时间" prop="time"></el-table-column>
			<el-table-column label="接种方式" prop="way"></el-table-column>
			<el-table-column label="接种地点" prop="location"></el-table-column>
			<el-table-column label="接种针数" prop="num"></el-table-column>
			<el-table-column label="厂家" prop="manufacturers"></el-table-column>
			<el-table-column label="备注" prop="remark"></el-table-column>
		</el-table>
		<div class="margin-t-10 flex">
			<el-pagination background layout="prev, pager, next, total" :total="vaccinumTotal"
				:current-page.sync="vaccinumQueryForm.page" :page-size="vaccinumQueryForm.page_size" @current-change="getVaccinumData">
			</el-pagination>
		</div>

		<h3>今日住院保障记录</h3>
		<el-button @click="getHospitalData" size="small" style="margin: 10px 0;">刷新</el-button>

		<el-table :data="hospitalTableData" v-loading="hospitalTableLoading" border stripe
			:header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
			<el-table-column prop="member.realname" label="姓名"></el-table-column>
			<el-table-column prop="hospitalized_time" label="住院日期"></el-table-column>
			<el-table-column prop="discharge_time" label="出院日期"></el-table-column>
			<el-table-column prop="hospital" label="医院"></el-table-column>
			<el-table-column prop="department" label="科室"></el-table-column>
			<el-table-column prop="number" label="床号"></el-table-column>
			<el-table-column label="住院状态" v-slot="{ row }">{{ inHospitalStatusDict[row.status].label }}
			</el-table-column>
			<el-table-column prop="adminer" label="记录人"></el-table-column>
		</el-table>
		<div class="margin-t-10 flex">
			<el-pagination background layout="prev, pager, next, total" :total="hospitalTotal"
				:current-page.sync="hospitalQueryForm.page" :page-size="hospitalQueryForm.page_size" @current-change="getHospitalData">
			</el-pagination>
		</div>

		<!-- 新增、编辑的弹窗 -->
		<el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="closeDialog"
			width="800px">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px">
				<el-row class="margin-t-20">
					<el-col :span="12">
						<el-form-item label="服务类型" prop="type" v-show="needServeOption">
							<el-select v-model="form.type" placeholder="请选择" class="el-input_inner--rewrite">
								<el-option v-for="item in serviceTypeDict" :key="item.value" :label="item.name"
									:value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item v-if="form.type == 21" label="服务名称" prop="serve_name">
							<el-input v-model="form.serve_name"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24">
					</el-col>
					<div v-for="(temp, i) in form.array" :key="i">
						<el-col :span="10">
							<el-form-item label="姓名" prop="member_id">
								<el-select v-model="temp.member_id" clearable filterable remote placeholder="输入姓名进行搜索"
									:remote-method="getUserRecords" :loading="userLoadig" class="el-input_inner--rewrite">
									<el-option v-for="item in userRecords" :key="item.id" :label="item.realname" :value="item.id">
										<div class="flex justify-content-s-b">
											<span>{{ item.realname }}</span>
											<span style="color: #8492a6;">{{ item.mobile }}</span>
										</div>
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="10">
							<el-form-item label="结果" prop="result">
								<el-input v-model="temp.result">
									<template v-if="form.type == 6 || form.type == 7" slot="append">{{ form.type == 6 ? 'mmHg' : 'mmol/L' }}</template>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-button style="margin: 5px 20px;" type="danger" size="mini" @click="handleDeleteArray(i)">删除</el-button>
						</el-col>
					</div>
					<el-col :span="24" style="display: flex;align-items: center;justify-content: center;">
						<el-button type="primary" size="mini" @click="handleAddArray">添加一项</el-button>
					</el-col>
				</el-row>
			</el-form>
			<span slot="footer">
				<el-button @click="closeDialog">取消</el-button>
				<el-button :loading="submitting" @click="handleSubmit" type="primary">提交</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		inHospitalStatusDict
	} from '@/utils/dict.js'
	import {
		formatDate
	} from '@/utils/common-fun.js'
	import {
		getUserRecordDataAPI
	} from '@/api/user-record/record.js'
	import {
		addServiceRecordAPI,
		getServiceRecordStatisticsAPI,
		getHealthDiagnoseListAPI,
		getVaccineRecordListAPI,
		getHospitalizedRecordListAPI,
	} from '@/api/outpatient.js'

	export default {
		name: 'OutpatientStatistics',
		data() {
			return {
				inHospitalStatusDict,
				serviceTypeDict: {
					1: {
						name: '取药',
						value: 1
					},
					2: {
						name: '理疗',
						value: 2
					},
					3: {
						name: '康复训练',
						value: 3
					},
					4: {
						name: '吸氧',
						value: 4
					},
					5: {
						name: '雾化',
						value: 5
					},
					6: {
						name: '血压',
						value: 6
					},
					7: {
						name: '血糖',
						value: 7
					},
					8: {
						name: '心电',
						value: 8
					},
					9: {
						name: '换药',
						value: 9
					},
					10: {
						name: '配药',
						value: 10
					},
					21: {
						name: '其他',
						value: 21
					},
				},
				dateRange: [],
				query: {
					start: '',
					end: '',
				},
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},

				// 用户档案
				userRecords: [],
				userLoadig: false,

				// 表单
				showDialog: false,
				submitting: false,
				form: {
					type: '',
					serve_name: '',
					array: [
						{
							member_id: '',
							result: ''
						}
					],
				},
				rules: {
					// member_id: [{
					// 	required: true,
					// 	message: '名称不能为空',
					// 	trigger: 'change'
					// }],
					type: [{
						required: true,
						message: '服务类型不能为空',
						trigger: 'change'
					}],
					type_str: [{
						required: true,
						message: '服务类型名称不能为空',
						trigger: 'blur'
					}],
				},

				services: [],
				loading: false,
				visit: 0,
				bus: 0,
				bih: 0,
				vaccine: 0, // 疫苗接种情况
				patrol: 0, // 健康巡诊

				// 今日就诊保障记录数据
				guaranteeTableData: [],
				// 今日就诊保障记录数据总数
				guaranteeTotal: 1,
				// 今日就诊保障记录表格加载状态
				guaranteeTableLoading: false,
				// 今日就诊保障记录请求参数
				guaranteeQueryForm: {
					page: 1,
					page_size: 10,
					start: '',
					end: ''
				},

				// 今日疫苗接种记录数据
				vaccinumTableData: [],
				// 今日疫苗接种记录数据总数
				vaccinumTotal: 1,
				// 今日疫苗接种记录表格加载状态
				vaccinumTableLoading: false,
				// 今日疫苗接种记录请求参数
				vaccinumQueryForm: {
					page: 1,
					page_size: 10,
					start: '',
					end: ''
				},

				// 今日疫苗接种记录数据
				hospitalTableData: [],
				// 今日疫苗接种记录数据总数
				hospitalTotal: 1,
				// 今日疫苗接种记录表格加载状态
				hospitalTableLoading: false,
				// 今日疫苗接种记录请求参数
				hospitalQueryForm: {
					page: 1,
					page_size: 10,
					start: '',
					end: ''
				},

				// 弹窗是否需要服务项选择
				needServeOption: false,
				// 弹窗标题
				dialogTitle: '',
			}
		},
		created() {
			this.getServiceRecordStatistics()

			let nowTime = formatDate(new Date());
			let nextTime = formatDate(new Date(+new Date() + (1000 * 60 * 60 * 24)));

			let now = nowTime.split(' ')[0] + ' 00:00:00';
			let next = nextTime.split(' ')[0] + ' 00:00:00';

			this.guaranteeQueryForm.start = now;
			this.guaranteeQueryForm.end = next;
			this.vaccinumQueryForm.start = now;
			this.vaccinumQueryForm.end = next;
			this.hospitalQueryForm.start = now;
			this.hospitalQueryForm.end = next;
			this.getGuaranteeData();
			this.getVaccinumData();
			this.getHospitalData();
		},
		methods: {
			// 新增按钮
			handleAdd(type) {
				this.showDialog = true
				if (typeof type == 'number') {
					this.form.type = type
					this.needServeOption = false;
					this.dialogTitle = '新增' + this.serviceTypeDict[type].name + '记录';
				} else {
					this.form.type = ''
					this.needServeOption = true;
					this.dialogTitle = '新增服务记录';
				}
				this.form.array = [{member_id: '', result: ''}];
			},
			handleDateChange(e) {
				if (e !== null) {
					this.query.start = e[0]
					this.query.end = e[1]
					this.getServiceRecordStatistics()
				}
			},
			closeDialog() {
				this.$refs.form.resetFields()
				this.showDialog = false
			},
			// 提交按钮
			handleSubmit() {
				this.$refs.form.validate(isValid => {
					if (!isValid) return
					let membersFlag = this.form.array.map(item => item.member_id === ''); // 校验是否都有选择老人
					if(membersFlag.includes(true)){
						this.$message.error('姓名必选');
						return
					}
					this.submitting = true
					const copyForm = JSON.parse(JSON.stringify(this.form))
					// if (copyForm.type != 21) {
					// 	copyForm.type_str = this.serviceTypeDict[copyForm.type].name
					// }
					let sendNumber = 0; // 成功的次数
					for (const item of copyForm.array) {
						let form = {
							member_id: item.member_id,
							type: copyForm.type,
							type_str: copyForm.type != 21 ? this.serviceTypeDict[copyForm.type].name : copyForm.serve_name,
							result: item.result
						}
						// 提交
						addServiceRecordAPI(form).then(res => {
							sendNumber += 1;
							if(sendNumber == copyForm.array.length){
								// 全部成功
								this.$message.success(res.msg)
								this.closeDialog()
								this.getServiceRecordStatistics()
							}
						}).finally(() => this.submitting = false)
					}
				})
			},
			getUserRecords(keyword) {
				this.userLoadig = true
				setTimeout(() => {
					getUserRecordDataAPI({
						keyword,
						page: 1
					}).then(res => {
						this.userRecords = res.data
						this.userLoadig = false
					})
				})
			},
			getServiceRecordStatistics() {
				this.loading = true
				getServiceRecordStatisticsAPI(this.query).then(res => {
					this.services = res.service
					this.bih = res.bih
					this.bus = res.bus
					this.visit = res.visit
					this.vaccine = res.vaccine
					this.patrol = res.patrol
					this.loading = false
				})
			},
			// 获取今日就诊保障记录
			getGuaranteeData(){
				this.guaranteeTableLoading = true
				getHealthDiagnoseListAPI(this.guaranteeQueryForm).then(res => {
					this.guaranteeTotal = res.total;
					this.guaranteeTableData = res.data;
					this.guaranteeTableLoading = false
				})
			},
			// 获取今日疫苗接种记录
			getVaccinumData(){
				this.vaccinumTableLoading = true
				getVaccineRecordListAPI(this.vaccinumQueryForm).then(res => {
					this.vaccinumTotal = res.total;
					this.vaccinumTableData = res.data;
					this.vaccinumTableLoading = false
				})
			},
			// 获取今日住院保障记录
			getHospitalData(){
				this.hospitalTableLoading = true
				getHospitalizedRecordListAPI(this.hospitalQueryForm).then(res => {
					this.hospitalTotal = res.total;
					this.hospitalTableData = res.data;
					this.hospitalTableLoading = false
				})
			},
			// 删除弹窗中的当前项
			handleDeleteArray(i){
				this.form.array.splice(i, 1);
			},
			// 添加弹窗中的项
			handleAddArray(){
				this.form.array.push({member_id: '', result: ''});
			},
		}
	}
</script>

<style scoped>
	.num-statistics {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100px;
		cursor: pointer;
		margin: -1px 0 0 -1px;
		border: 1px solid #CCC;
	}

	.num-statistics_title {
		color: #606266;
	}

	.num-statistics_num {
		margin-top: 5px;
		font-size: 30px;
		color: #303133;
		font-weight: 700;
	}
	h3 {
		margin-top: 60px;
	}
</style>