// 字典

export const idTypeDict = {
	1: {
		label: '身份证',
		value: 1
	},
	2: {
		label: '港澳居住证',
		value: 2
	},
	3: {
		label: '护照',
		value: 3
	},
	4: {
		label: '军官证',
		value: 4
	},
	5: {
		label: '台湾居民来大陆通信证',
		value: 5
	},
	6: {
		label: '港澳居民来大陆通信证',
		value: 6
	},
	7: {
		label: '出生医学证明',
		value: 7
	}
}

export const sexDict = {
	1: {
		label: '男',
		value: 1
	},
	2: {
		label: '女',
		value: 2
	}
}

// 入住状态
export const liveStatusDict = {
	1: {
		label: '未入住',
		value: 1
	},
	2: {
		label: '已入住',
		value: 2
	}
}

// 住院状态
export const inHospitalStatusDict = {
	1: {
		label: '住院',
		value: 1,
	},
	2: {
		label: '出院',
		value: 2
	}
}
