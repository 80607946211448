// 健康档案
import request from '@/utils/util.js'

// 档案导入记录
export function getRecordImportHistoryAPI(data) {
	return request.get('/import_records', data)
}

// 档案导出记录
export function getRecordExportHistoryAPI(data) {
	return request.get('/export_records', data)
}

/**
 * @description 用户档案 
 */
// 新增、编辑
export function saveUserRecordAPI(data) {
	return request.post('/member/save', data)
}

// 列表
export function getUserRecordDataAPI(data) {
	return request.get('/member/list', data)
}

// 删除
export function delUserRecordDataAPI(data) {
	return request.get('/member/delete', data)
}

/**
 * @description 亲属档案 
 */
// 新增、编辑
export function saveRelationAPI(data) {
	return request.post('/member/comp_save', data)
}

// 列表
export function getRelationListAPI(data) {
	return request.get('/member/comp_list', data)
}

// 老干部过往危急病症和抢救预案设置
export function setMedicalHistoryAPI(data) {
	return request.post('/member/medical_history', data)
}